/* Default styles */

@import url('https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@900&display=swap');

.title {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: radial-gradient(rgba(#7600bf, 0.5) 0%, transparent 70%), linear-gradient(#0b161e 40%, #202076 70%);
	perspective: 700px;
	font-size: clamp(10px, 2vw, 20px);
}
  
h1 {
position: relative;
font-family: 'Exo';
font-size: 11vw;
margin: 0;
transform: skew(-15deg);
letter-spacing: 0.03em;

&::after {
	content: '';
	position: absolute;
	top: -0.1em;
	right: 0.05em;
	width: 0.4em;
	height: 0.4em;
	background: 
	radial-gradient(white 3%, rgba(white, 0.3) 15%, rgba(white, 0.05) 60%, transparent 80%),
	radial-gradient(rgba(white, 0.2) 50%, transparent 60%) 50% 50% / 5% 100%,
	radial-gradient(rgba(white, 0.2) 50%, transparent 60%) 50% 50% / 70% 5%;
	background-repeat: no-repeat;
}

span:first-child {
	display: block;
	text-shadow: 0 0 0.1em #8ba2d0, 0 0 0.2em black,  0 0 5em #165ff3;
	-webkit-text-stroke: 0.06em rgba(black, 0.5);
}

span:last-child {
	position: absolute;
	left: 0;
	top: 0;
	background-image: linear-gradient(#032d50 25%, #00a1ef 35%, white 50%, #20125f 50%, #8313e7 55%, #ff61af 75%);
	-webkit-text-stroke: 0.01em #94a0b9;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}
}

h2 {
font-family: 'Mr Dafoe';
margin: 0;
font-size: 10vw;
margin-top: -8vw;
color: white;
text-shadow: 0 0 0.05em #fff, 0 0 0.2em #fe05e1, 0 0 0.3em #fe05e1;
transform: rotate(-7deg);
}

.page-container {
  text-align: center;
  justify-content: center;
  margin: auto;
  align-content: center;
  align-items: center;
}

.title {
  font-size: 24px;
  margin-top: 2%;
}

.wojak {
  margin-bottom: -5px;
}

hr {
  border: 2px solid black;
  opacity: 0.4;
  margin-bottom: 0px;
  margin-top: 0px;
}

.container {
	display: flex;
	text-align: center;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center; /* Centering the buttons horizontally */
}

.container .btn {
	position: relative;
	top: 0;
	left: 0;
	width: 250px;
	height: 50px;
	margin: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.container .btn a {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 30px;
	padding: 10px;
	letter-spacing: 1px;
	text-decoration: none;
	overflow: hidden;
	color: #fff;
	font-weight: 400px;
	z-index: 1;
	transition: 0.5s;
	backdrop-filter: blur(15px);
}
.container .btn:hover a {
	letter-spacing: 3px;
}
.container .btn a::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
	transform: skewX(45deg) translate(0);
	transition: 0.5s;
	filter: blur(0px);
}
.container .btn:hover a::before {
	transform: skewX(45deg) translate(200px);
}
.container .btn::before {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	bottom: -5px;
	width: 30px;
	height: 10px;
	background: #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
.container .btn:hover::before /*lightup button*/ {
	bottom: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}

.container .btn::after {
	content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	top: -5px;
	width: 30px;
	height: 10px;
	background: #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
}
.container .btn:hover::after /*lightup button*/ {
	top: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}
.container .btn:nth-child(1)::before, /*chnage 1*/
.container .btn:nth-child(1)::after {
	background: #1DA1F2;
	box-shadow: 0 0 5px #1DA1F2, 0 0 15px #1DA1F2, 0 0 30px #1DA1F2,
		0 0 60px #1DA1F2;
}
.container .btn:nth-child(2)::before, /* 2*/
.container .btn:nth-child(2)::after {
	background: #8e30eb;
	box-shadow: 0 0 5px #8e30eb, 0 0 15px #8e30eb, 0 0 30px #8e30eb,
		0 0 60px #8e30eb;
}
.container .btn:nth-child(3)::before, /* 3*/
.container .btn:nth-child(3)::after {
	background: #000000;
	box-shadow: 0 0 5px #000000, 0 0 15px #000000, 0 0 30px #000000,
		0 0 60px #000000;
}
.container .btn:nth-child(4)::before, /* 4*/
.container .btn:nth-child(4)::after {
	background: #ffc300;
	box-shadow: 0 0 5px #ffc300, 0 0 15px #ffc300, 0 0 30px #ffc300,
		0 0 60px #ffc300;
}
.container .btn:nth-child(5)::before, /* 5*/
.container .btn:nth-child(5)::after {
	background: #FF0000;
	box-shadow: 0 0 5px #FF0000, 0 0 15px #FF0000, 0 0 30px #FF0000,
		0 0 60px #FF0000;
}

body {
  margin: 0;
  background-color: #1f1f1f;
  font-family: 'Courier New', Courier, monospace;
  background-image: linear-gradient(to right,#ffab00, #ff01fd);
}



a:link {
  color: green;
}

.link-list {
  align-items: center;
  flex-direction: column; /* Added to list items horizontally */
  margin: auto;
  text-align: center;
  justify-content: center;
  align-content: center;
}

/* Responsive styles */

@media only screen and (max-width: 800px) {

	h1 {
		position: relative;
		font-family: 'Exo';
		font-size: 20vw;
		margin: 0;
		transform: skew(-15deg);
		letter-spacing: 0.03em;
	}
	h2 {
		font-family: 'Mr Dafoe';
		margin: 0;
		font-size: 20vw;
		margin-top: -15vw;
		color: white;
		text-shadow: 0 0 0.05em #fff, 0 0 0.2em #fe05e1, 0 0 0.3em #fe05e1;
		transform: rotate(-7deg);
		}

}
